/* src/components/ChatMessage.css */
.code-style-body {
  position: relative;
  background-color: rgb(43, 43, 43); /* 自定義背景色 */
  font-size: 0.85em; /* 設定字型大小 */
  padding: 5px 10px;
  border-top-right-radius: 5px; /* 右上圓弧 */
  color: #000000;
}

.code-style-body > pre {
  margin: 0;
}

.code-style-bottom {
  background-color: #abab9f; /* 自定義背景色，可根據需要調整 */
  font-size: 0.7em; /* 設定字型大小，可根據需要調整 */
  /* font-weight: bold; */
  font-style: italic;
  padding: 5px 10px;
  border-bottom-left-radius: 5px; /* 左下圓弧 */
  border-bottom-right-radius: 5px; /* 右下圓弧 */
  color: #ffffff; /* 文字顏色，可根據需要調整 */
  text-align: right;
}

.code-style-top {
  background-color: #abab9f; /* 自定義背景色，可根據需要調整 */
  font-size: 0.7em; /* 設定字型大小，可根據需要調整 */
  /* font-weight: bold; */
  font-style: italic;
  padding: 5px 10px;
  border-top-left-radius: 5px; /* 左下圓弧 */
  border-top-right-radius: 5px; /* 右下圓弧 */
  color: #ffffff; /* 文字顏色，可根據需要調整 */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hidden {
  display: none;
}

.markdown-wrapper > * {
  margin: 0px;
}

.markdown-wrapper p {
  margin: 3px;
}
