.button-base {
  padding: 5px 10px;
  transform: scale(0.7);
  border: none;
  color: rgb(127, 127, 127);
  background-color: #9e9e9e13;
  border-radius: 5px;
}

.button-base:hover {
  color: white;
}

.button-base:active {
  color: white;
  background-color: #808080; /* 鼠標按下時的背景色 */
}

.button-code-copy-base {
  transform-origin: top right;
  transform: scale(0.7);
  border: none;
}

.button-code-copy-base:active {
  background-color: #808080; /* 鼠標按下時的背景色 */
}
